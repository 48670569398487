.rating span {
  margin: 0.1rem;
}

.rating svg {
  color: #f8e825;
}

.rating {
  color: #f8e825;
}

.rating-text {
  font-weight: 600;
  padding-left: 1rem;
  color: rgb(51, 51, 51);
}

.nav-tabs-custom > .nav-tabs > li.active {
  border-top-color: #dd4b39;
}

.user-image {
  object-fit: cover !important;
}

.header-user-image {
  object-fit: cover !important;
}

/* to overwrite the hover gray of adminlte */
.box-tools .btn-success:hover {
  color: #fff !important;
  background-color: #449d44 !important;
  border-color: #398439 !important;
}

/* to remove border top when displaying data using table */
.no-border-top {
  border-top: 1px solid transparent !important;
}
